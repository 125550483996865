import { get, delete0 } from '@/utils/request'

const visitApi = {
  visitRecordApi: '/api/content/video/visit'
}

// 获取用户的视频观看记录
export function getVisitRecord(nextId) {
  return get(visitApi.visitRecordApi + '?nextId=' + nextId)
}

// 删除用户的视频观看记录
export function deleteVisitRecord(nextId) {
  return delete0(visitApi.visitRecordApi + '?nextId=' + nextId)
}

// 清空用户的视频观看记录
export function eraseVisitRecord(nextId) {
  return delete0(visitApi.visitRecordApi + '?nextId=' + nextId)
}
