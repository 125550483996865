<template>
  <div id="history-list">
    <!--搜索结果面包屑-->
    <el-breadcrumb
      v-if="this.$route.path.indexOf('history') > -1"
      class="bread"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item :to="{ path: '' }"><a href="/">返回首页</a></el-breadcrumb-item>
      <el-breadcrumb-item>播放历史记录：共<span class="reslut">({{ totalSize }}}</span>条</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row v-if="visitList.length !== 0" class="movie-list">
      <el-col style="text-align: center">
        <el-button
          type="danger"
          icon="el-icon-delete"
          round
          title="一键清空历史"
          @click="removeAll"
        >一键清空历史</el-button>
      </el-col>
      <el-col :md="8">
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(record, index) in visitList"
            :key="index"
            :timestamp="record.createAt"
            placement="top"
          >
            <history-video-card :video="record" />
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="small"
              title="移除该历史记录"
              @click.stop="removeHistory(record.videoId)"
            />
          </el-timeline-item>
        </el-timeline>
      </el-col>
    </el-row>
    <el-row v-else class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-history.png">
        <div>你还没有看过任何东西呢</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HistoryVideoCard from '@/components/card/HistoryVideoCard'
import { getVisitRecord } from '@/api/visit'

export default {
  name: 'History',
  components: { HistoryVideoCard },
  data() {
    return {
      reverse: false,
      totalSize: 0,
      nextId: 0,
      nextId1: 0,
      visitList: [],
      showEmpty: false
    }
  },
  created() {
    document.title = '我的历史记录'
    this.getVisitRecordWrapper(this.nextId)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollEvent)
  },
  methods: {
    handleScrollEvent() {
      if (document.body.scrollHeight <= window.screen.height + document.documentElement.scrollTop) {
        console.log('滚动条触底, 加载数据')
        if (this.nextId1 !== this.nextId) {
          this.nextId1 = this.nextId
          this.getVisitRecordWrapper(this.nextId)
        }
      }
    },
    getVisitRecordWrapper(nextId) {
      getVisitRecord(nextId).then(res => {
        if (res.code === 0) {
          const resData = res.data
          this.totalSize = resData.totalSize
          this.nextId = resData.nextId
          for (const item of resData.list) {
            this.visitList.push(item)
          }
        }
      })
    },
    // 清除当前历史记录
    removeHistory(videoId) {
      this.$confirm('确认移除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除 ' + videoId + ' 这条记录')
        // 确认
        /* deleteHistory(this.uid, video.vid).then(res => {
          // 将要删除的当前video对象移除数组
          // 获取下标
          const index = this.videos.indexOf(video)
          if (index > -1) {
            this.videos.splice(index, 1)
          }
        })*/

        this.$message({
          type: 'success',
          message: '移除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 清空所有历史记录
    removeAll() {
      // 移除所有收藏
      this.$confirm('确认移除所有播放历史记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除全部记录')

        /* const arr = []
        for (const i of this.videos) {
          arr.push(i.vid)
        }*/
        // const vidStr = arr.join(',')
        // 确认
        /* deleteHistory(this.uid, vidStr).then(res => {
          this.videos = []
        })*/

        this.$message({
          type: 'success',
          message: '移除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped>
#history-list {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 30px;
}

.bread {
  font-size: 15px;
}

.movie-list {
  padding-top: 15px;
}

.reslut {
  color: red;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.remove-slot {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
</style>
