<template>
  <el-col style="padding-right: 7px; padding-left: 7px">
    <div style="cursor: pointer" :title="video.title">
      <el-card :body-style="{ padding: '0px' }" class="card">
        <router-link target="_blank" :to="`/video/${video.videoId}`">
          <div class="imgs">
            <el-image
              lazy
              fit="cover"
              :src="video.coverUrl"
              class="coverImg"
            />
            <span style="position: absolute; bottom: 0; left: 0; color:white">
              <i v-if="video.horizontal" class="el-icon-monitor" />
              <i v-else class="el-icon-mobile-phone" />
            </span>
            <span style="position: absolute; bottom: 0; left: 10%; color:white">
              <i class="el-icon-video-play">{{ getVisited(video.view) }}</i>
            </span>
            <span style="position: absolute; bottom: 0; left: 30%; color:white">
              <i class="el-icon-s-comment">{{ getVisited(video.comment) }}</i>
            </span>
            <span style="position: absolute; bottom: 0; right: 0; color:white"> {{ video.duration }} </span>
          </div>
        </router-link>
        <el-progress :percentage="setItemProgress(video)" />
        <div style="padding: 14px">
          <router-link target="_blank" :to="`/video/${video.videoId}`">
            <span style="left: 0;margin-bottom: 0px;color: black;">{{ video.title | ellipsis }}</span>
          </router-link>
        </div>
        <div style="padding: 14px">
          <span style="left: 0;margin-bottom: 0px;color: black;">
            <router-link target="_blank" :to="`/user/${video.user.userId}`">
              <i class="el-icon-user"> {{ video.user.screenName | ellipsisUsername }} </i></router-link> • {{ video.publishAt }}
          </span>
        </div>
      </el-card>
    </div>
  </el-col>
</template>

<script>
import { handleVisited } from 'assets/js/utils'

export default {
  name: 'HistoryVideoCard',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 10
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 5
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  props: {
    video: {
      type: Object,
      default: null
    },
    // 时间前的描述
    dateTit: {
      type: String,
      default: ''
    }
  },
  methods: {
    getVisited(visited) {
      return handleVisited(visited)
    },
    convertTimestamp(value) {
      const date = new Date(value * 1000)
      var month = date.getMonth()
      if (month < 10) {
        if (month === 0) {
          month = '01'
        } else {
          month = '0' + month
        }
      }

      var day = date.getDay()
      if (day < 10) {
        day = '0' + day
      }
      return month + '-' + day
    },
    setItemProgress(video) {
      const progress = Math.floor(video.currentTime / video.duration * 100)
      return progress
    }
  }
}
</script>

<style scoped>
.time {
  font-size: 15px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.tit {
  font-weight: 700;
  font-size: 18px;

  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsisUsername;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*行数*/
  -webkit-box-orient: vertical;
}

.num {
  position: relative;
  font-size: 15px;
  padding-top: 9px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .tit {
    font-weight: 600;
    font-size: 12px;
    height: 32px;
  }
  .time {
    font-size: 10px;
    color: #999;
  }
  .num {
    font-size: 9px;
    padding-top: 3px;
  }
  .bottom {
    margin-top: 2px;
    line-height: 7px;
  }
  .coverImg {
    height: 120px !important;
  }
}

.coverImg {
  width: 100%;
  height: 175px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

/*.card:hover {
  !*鼠标放上之后元素变成1.06倍大小*!
  transform: scale(1.06);
}*/
.imgs {
  position: relative;
}
.play-icon {
  position: absolute;
  /*top: -15px;*/
  right: 2%;
  bottom: 5px;
  z-index: 7;
  width: 40px;
}
</style>
